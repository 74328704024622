import React from "react";
import styled from "styled-components";
import params from "../utils/params";
import useWindowDimensions from "../utils/useWindowDimensions";

const Story = styled.div`
  font-size: 1.5vw;
  line-height: 2.45vw;
  text-align: justify;
  text-align-last: left;
  width: 100%;
  white-space: pre-line;
  font-family: "EB Garamond", "Segoe UI Emoji", "Segoe UI Symbol", "Apple Color Emoji";
  ${(props) =>
    !props.children &&
    props.view !== "printPreview" &&
    props.view !== "print" &&
    `height: 100%;
    background-image: linear-gradient(black 0.3vw, transparent 0.3vw);
    background-size: 100% 2.45vw;
  `}
  ${(props) =>
    !props.children &&
    (props.view === "printPreview" || props.view === "print") &&
    `height: 100%;
  `}
  ${(props) => props.tac && `text-align: center; text-align-last: center;`}
`;

const StoryComponent = ({ children, className, onClick, tac }) => {
  const { view } = params;
  const { width } = useWindowDimensions();
  const borderWidth = Math.round(width * 0.002) || 1;

  return (
    <Story
      key={children}
      className={className}
      onClick={onClick}
      view={view}
      tac={tac}
      borderWidth={borderWidth}
    >
      {/* replace new lines and old emojis like heart suit - heart suit replacement should really be fixed in Puppeteer - replace U+fffc with nothing */}
      {children
        ?.replace(/\n/g, "\r\n")
        ?.replace(/♥️/g, "❤️")
        ?.replace(/\uFFFC/g, "")}
    </Story>
  );
};

export default StoryComponent;
